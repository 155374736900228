.app {
    /* min-width: 1920px;
    min-height: 1080px; */
}

@media (min-width: 768px) {
    .container {
        max-width: 900px !important;
    }
}

.lg\:space-y-14>:not([hidden])~:not([hidden]) {
    margin-top: 16px !important;
}

.option-text {
    font-size: 7px;
}

.nc-PageHome2,
.nc-PageCollection,
.nc-ProductDetailPage {
    background: rgb(213, 213, 213);
    background: linear-gradient(66deg, rgba(213, 213, 213, 1) 0%, rgba(250, 250, 250, 1) 100%);
}

.nc-MainNav2Logged,
.nc-Footer {
    background: rgb(0, 60, 165);
    background: linear-gradient(90deg, rgba(0, 60, 165, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 184, 169, 1) 100%);
}

.nc-SectionPromo2 .container {
    background: rgb(12, 122, 173);
    background: linear-gradient(66deg, rgba(12, 122, 173, 1) 0%, rgba(0, 184, 169, 0.5970982142857143) 100%);
}

.space-y-7> :not([hidden])~ :not([hidden]) {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.navigation-button {
    height: 18px !important;
    padding: 6px 8px !important;
    margin-top: 0 !important;
}