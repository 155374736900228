// Kanit
@font-face {
  font-family: Kanit;
  font-weight: 300;
  src: url("../fonts/Kanit/Kanit-Light.ttf");
}

@font-face {
  font-family: Kanit;
  font-weight: 400;
  src: url("../fonts/Kanit/Kanit-Regular.ttf");
}

@font-face {
  font-family: Kanit;
  font-weight: 500;
  src: url("../fonts/Kanit/Kanit-Medium.ttf");
}

@font-face {
  font-family: Kanit;
  font-weight: 600;
  src: url("../fonts/Kanit/Kanit-SemiBold.ttf");
}

@font-face {
  font-family: Kanit;
  font-weight: 700;
  src: url("../fonts/Kanit/Kanit-Bold.ttf");
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: Kanit;
  --font-body: Kanit;
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}